import { SettingOutlined } from '@ant-design/icons'
import { App, Button, Col, Row } from 'antd'
import LoadingProgress from 'components/LoadingProgress/LoadingProgress'
import useStore from 'hooks/useStore'
import useSubscription from 'hooks/useSubscription'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TaskGroup } from 'types/Tasks'
import AccountFilter from './AccountFilter/AccountFilter'
import styles from './AccountGroupFilter.module.scss'
import AddGroupModal from './AddGroupModal'
import GroupFilter from './GroupFilter/GroupFilter'

type AccountGroupFilterProps = {
  label: string
}

const AccountGroupFilter = ({ label }: AccountGroupFilterProps) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false)
  const { getUserGroups } = useStore((state) => state)
  const { getUserConfig, selectedGroups, setSelectedGroups, isAllSelected } =
    useStore((state) => state)
  const { tasks } = useStore((state) => state)

  const user = useStore((state) => state.user?.data)
  const email = user?.email || ''
  const app = App.useApp()

  const getWPLoginUrl = useStore((state) => state.getWPLoginUrl)
  const { updateBillingUrl, updateSubscriptionPackage } = useSubscription(
    (state) => state,
  )
  const getSubscriptionPackage = useStore(
    (state) => state.getSubscriptionPackage,
  )

  useEffect(() => {
    getWPLoginUrl()
      .then((data) => {
        if (data) {
          updateBillingUrl(data)
        }
      })
      .catch((error) => {
        updateBillingUrl('')
      })
  }, [getWPLoginUrl, updateBillingUrl])

  useEffect(() => {
    getSubscriptionPackage().then((data) => {
      const { package: pkg } = data
      const snake_case_pkg = pkg
        .toLowerCase() // Converts the string to lowercase
        .replace(/\s+/g, '_') // Replaces spaces with _

      updateSubscriptionPackage(snake_case_pkg)
    })
  }, [getSubscriptionPackage, updateSubscriptionPackage])

  useEffect(() => {
    setLoading(true)
    getUserGroups()
      .then((data: TaskGroup[]) => {
        getUserConfig().then((value) => {
          if (value.preferences?.selectedGroups) {
            setSelectedGroups(value.preferences.selectedGroups, true)
          } else {
            setSelectedGroups(
              data
                .filter(
                  (group) =>
                    group.groupMembers?.find((gm) => gm.user === user?.id)
                      ?.status !== 'inactive' && group.status !== 'archived',
                )
                .map((group) => group.id),
            )
          }
        })
      })
      .catch((error) => {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }, [
    setLoading,
    getUserConfig,
    getUserGroups,
    setSelectedGroups,
    app.notification,
    user?.id,
  ])

  const changeFilterStatus = useCallback(
    (id: number) => {
      const currentFilterIndex = selectedGroups.indexOf(id)
      const newFilters =
        currentFilterIndex > -1
          ? selectedGroups.filter((filter) => filter !== id)
          : selectedGroups.concat(id)
      setSelectedGroups(newFilters)
    },
    [selectedGroups, setSelectedGroups],
  )

  const selectAll = useCallback(() => {
    if (tasks.groups?.length) {
      setSelectedGroups(tasks.groups.map((group) => group.id))
    }
  }, [tasks, setSelectedGroups])

  const unselectAll = useCallback(() => {
    setSelectedGroups([])
  }, [setSelectedGroups])

  if (loading) {
    return <LoadingProgress />
  }

  return (
    <Row
      gutter={16}
      wrap={false}
      justify="space-between"
      className={styles.filtersContainer}
    >
      <Col flex="1 1 0px">
        <Row wrap={false}>
          <Col>
            <AccountFilter label={email} />
          </Col>
          <Col flex={1}>
            <GroupFilter
              filters={
                tasks?.groups?.filter(
                  (group) =>
                    group.groupMembers?.find((gm) => gm.user === user?.id)
                      ?.status !== 'inactive' && group.status !== 'archived',
                ) || []
              }
              selected={selectedGroups}
              onChange={changeFilterStatus}
            />
          </Col>
        </Row>
      </Col>
      <Col flex="0 1 auto">
        <Row justify="space-between">
          <Col>
            <Button
              size="small"
              type="default"
              onClick={isAllSelected() ? unselectAll : selectAll}
            >
              {isAllSelected()
                ? t('my-today-page.unselect-all')
                : t('my-today-page.select-all')}
            </Button>
          </Col>
          <Col>
            <Link to="/settings?activeTab=groups">
              <Button
                icon={<SettingOutlined />}
                size="small"
                type="text"
              ></Button>
            </Link>
          </Col>
        </Row>
      </Col>
      <AddGroupModal
        isOpen={isAddGroupModalOpen}
        handleClose={() => setIsAddGroupModalOpen(false)}
      />
    </Row>
  )
}

export default AccountGroupFilter
